<template>
  <div class="bg-white shadow rounded mt-3 pt-3 px-2 pb-4.5">
    <div class="flex justify-between">
      <p class="font-headers font-bold text-mdl text-grey-light">
        Overview
      </p>
      <div class="min-w-1/4">
        <Select
          :options="allProductsInvitesInfo"
          :value="allProductsInvitesInfo[0]"
          :onSelect="(value) => (invites = value)"
          label="Redeemed Invites by Product"
        />
      </div>
    </div>
    <div class="mt-3 px-2 flex justify-between" v-if="allInvitesInfo">
      <div>
        <p class="text-grey-light text-sm-2">
          Total Purchased - Limited Invites
        </p>
        <p class="font-medium text-mdlh text-grey-dark-1">
          {{ allInvitesInfo.totalPurchasedLimitedInvites }}
        </p>
      </div>
      <div>
        <p class="text-grey-light text-sm-2">
          Companies with Unlimited Invites
        </p>
        <p class="font-medium text-mdlh text-grey-dark-1">
          {{ allInvitesInfo.companiesWithUnlimitedInvites }}
        </p>
      </div>
      <div>
        <p class="text-grey-light text-sm-2">Redeemed</p>
        <p class="font-medium text-mdlh text-grey-dark-1">
          {{ invites }}
        </p>
      </div>
      <div>
        <p class="text-grey-light text-sm-2">Remaining</p>
        <p class="font-medium text-mdlh text-grey-dark-1">
          {{ allInvitesInfo.remaining }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select/NewSelect.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "InvitesOverview",
  components: { Select },
  computed: {
    ...mapState({
      allInvitesInfo: (state) => state.invites.allInvitesInfo,
      allProductsInvitesInfo: (state) => state.invites.allProductsInvitesInfo,
      redeemedInvites: (state) => state.invites.redeemedInvites,
    }),
    invites: {
      get() {
        return this.redeemedInvites;
      },
      set(value) {
        this.setRedeemedInvites(value?.invitesRedeemed);
      },
    },
  },
  methods: {
    ...mapActions({
      getAllInvitesInfo: "invites/getAllInvitesInfo",
      getAllProductsInvitesInfo: "invites/getAllProductsInvitesInfo",
      setRedeemedInvites: "invites/setRedeemedInvites",
    }),
  },
  async mounted() {
    await this.getAllInvitesInfo(0);
    await this.getAllProductsInvitesInfo();
  },
};
</script>
