<template>
  <div class="bg-white shadow rounded mt-3 pt-3 px-2 pb-4.5">
    <div class="flex justify-between">
      <p class="font-headers font-bold text-mdl text-grey-light">
        Companies Invites Status
      </p>
      <Button
        type="secondary"
        size="medium"
        text="Export"
        :iconLeft="exportIcon"
        @click.native="exportData"
        data-testid="export-button"
      />
    </div>
    <CompaniesInvitesTable />
  </div>
</template>

<script>
import Button from "@/components/Button/Button.vue";
import exportIcon from "@/assets/img/icons/export.svg";
import CompaniesInvitesTable from "./CompaniesInvitesTable";
import { mapActions, mapState } from "vuex";

export default {
  name: "CompaniesInvitesStatus",
  components: { Button, CompaniesInvitesTable },
  data: () => ({
    exportIcon,
  }),
  computed: {
    ...mapState({
      companies: state => state.invites.companies
    })
  },
  methods: {
    ...mapActions({
      getAllOrgsInfo: 'invites/getAllOrgsInfo',
      exportCompaniesCsv: 'invites/exportCompaniesCsv',
    }),
    exportData() {
      this.exportCompaniesCsv(this.$papa);
    },
  },
  async mounted() {
    this.getAllOrgsInfo({clear: true});
  }
};
</script>
