<template>
  <div class="text-sm-2 text-grey-light font-medium text-center">
    <slot v-if="!hasSorting" />
    <button
      v-else
      class="flex flex-row justify-center items-center focus:outline-none"
      :class='margins'
      @click="sortCallback"
    >
      <slot />
      <img
        src="@/assets/img/delta-table/caret.svg"
        class="transition-all ml-0.4"
        :class="arrowDirection ? 'transform rotate-180' : null"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "TableHeader",
  props: {
    hasSorting: { type: Boolean, default: false },
    sortCallback: { type: Function, default: () => {} },
    arrowDirection: { type: Boolean, default: false },
    alignment: { type: String, default: "center" },
  },
  computed: {
    margins() {
      switch (this.alignment) {
        case "center":
          return "mx-auto";
        case "left":
          return "mr-auto";
        case "right":
          return "ml-auto";
        default:
          return "mx-auto";
      }
    },
  },
};
</script>
