<template>
  <div>
    <div
      class="grid grid-cols-7 text-md text-grey-dark-2 text-center py-2 px-1"
    >
      <p class="col-span-3 text-left text-grey-dark-1 font-semibold">
        {{ source.companyName }}
      </p>
      <p :class="subDetails.class">{{ subDetails.label }}</p>
      <p>{{ source.purchased }}</p>
      <p>{{ source.redeemed }}</p>
      <p>{{ source.isUnlimited ? 'Unlimited' : source.remaining }}</p>
    </div>
    <div class="w-full h-line bg-grey-light-4" />
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    subDetails() {
      switch(parseInt(this.source.subscriptionStatus, 10)) {
        case 1:
          return ({
            label: 'Active',
            class: 'text-green'
          });
        case 2:
          return ({
            label: 'Deactivated',
            class: 'text-red'
          });
        case 3:
          return ({
            label: 'Suspended',
            class: 'text-grey-medium-2'
          });
        case 4:
          return ({
            label: 'Expired',
            class: 'text-red'
          });
        default:
          return ({
            label: 'No status',
            class: 'text-grey-medium-2'
          });
      }
    }
  },
};
</script>
