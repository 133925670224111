<template>
  <div>
    <p class="font-headers font-bold text-xl text-grey-dark-2">
      Invites Status
    </p>
    <InvitesOverview />
    <CompaniesInvitesStatus />
  </div>
</template>

<script>
import CompaniesInvitesStatus from '../components/CompaniesInvitesStatus.vue';
import InvitesOverview from '../components/InvitesOverview.vue';

export default {
  name: 'InvitesManagement',
  components: { InvitesOverview, CompaniesInvitesStatus },
};
</script>
