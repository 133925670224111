<template>
  <div>
    <div class="mt-3 flex justify-between">
      <InputField
        placeholder="Search by name"
        label="Filter by"
        :onChange="setKeyword"
        :propValue="keyword"
        leftIcon
        class="w-inputField"
        data-testid="filter-field"
      />

      <div class="flex justify-center items-center">
        <DatePicker
          v-model="start"
          mode="date"
          :max-date="end"
          timezone="UTC"
          data-testid="start-date-select"
          :model-config="{
            type: 'string',
            mask: 'iso',
            timeAdjust: '06:00:00',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
                From
              </p>
              <div class="flex">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                />
                <img
                  src="@/assets/img/icons/date.svg"
                  class="relative right-4"
                />
              </div>
            </div>
          </template>
        </DatePicker>
        <DatePicker
          v-model="end"
          mode="date"
          :min-date="start"
          timezone="UTC"
          data-testid="end-date-select"
          :model-config="{
            type: 'string',
            mask: 'iso',
            timeAdjust: '11:59:59',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
                To
              </p>
              <div class="flex">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
                /><img
                  src="@/assets/img/icons/date.svg"
                  class="relative right-4"
                />
              </div>
            </div>
          </template>
        </DatePicker>
      </div>
    </div>
    <div>
      <p class="my-2 text-grey-dark-2 text-sm-2">
        Showing {{ companies.length }} companies out of {{ totalCount }}
      </p>
      <div>
        <div class="grid grid-cols-7 items-center bg-grey-light-3 py-0.4">
          <TableHeader
            hasSorting
            @click.native="sortList(0)"
            alignment="left"
            class="col-span-3"
            :arrowDirection="this.allOrgsData.sortingMatrix[0] === -1"
          >
            COMPANY NAME
          </TableHeader>
          <TableHeader
            hasSorting
            @click.native="sortList(1)"
            :arrowDirection="this.allOrgsData.sortingMatrix[1] === -1"
            >SUBSCRIPTION</TableHeader
          >
          <TableHeader
            hasSorting
            @click.native="sortList(2)"
            :arrowDirection="this.allOrgsData.sortingMatrix[2] === -1"
            class="mr-1.5"
            >PURCHASED</TableHeader
          >
          <TableHeader
            hasSorting
            @click.native="sortList(3)"
            :arrowDirection="this.allOrgsData.sortingMatrix[3] === -1"
            class="mr-2"
            >REDEEMED</TableHeader
          >
          <TableHeader
            hasSorting
            @click.native="sortList(4)"
            :arrowDirection="this.allOrgsData.sortingMatrix[4] === -1"
            class="mr-4"
            >REMAINING</TableHeader
          >
        </div>

        <VirtualList
          style="min-height: 200px; max-height: 364px; overflow-y: auto"
          :data-key="'uuid'"
          :data-sources="companies"
          :data-component="itemComponent"
          data-testid="invites-history-table"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import InputField from "@/components/InputField/InputField.vue";
import add from "@/assets/img/icons/add.svg";
import minus from "@/assets/img/icons/minus.svg";
import TableHeader from "@/components/Table/TableHeader.vue";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import debounce from "lodash/debounce";

Vue.component("date-picker", DatePicker);

export default {
  name: "CompaniesInvitesTable",
  components: { TableHeader, VirtualList, DatePicker, InputField },
  data: () => ({
    add,
    minus,
    itemComponent: ListItem,
    companiesList: [],
  }),
  computed: {
    ...mapState({
      allOrgsData: (state) => state.invites.allOrgs,
      totalCount: state => state.invites.companiesTotalCount,
      filters: state => state.invites.allOrgs,
      companies: state => state.invites.companies,
    }),
    keyword: {
      get() {
        return this.allOrgsData.keyword;
      },
      set(val) {
        this.setOrgPayload({ field: "keyword", value: val });
      },
    },
    start: {
      get() {
        return this.allOrgsData.startDate;
      },
      set(val) {
        this.setOrgPayload({ field: "startDate", value: val });
      },
    },
    end: {
      get() {
        return this.allOrgsData.endDate;
      },
      set(val) {
        this.setOrgPayload({ field: "endDate", value: val });
      },
    },
  },
  methods: {
    ...mapActions({
      setOrgPayload: "invites/setOrgPayload",
      sortList: "invites/sortCompanies",
      resetCompanies: "invites/resetAllOrgsInfo",
    }),
    triggerFetch() {
      if (this.filters.pageNr < Math.ceil(this.totalCount / this.filters.pageSize)) {
        this.setOrgPayload({ field: "pageNr", value: this.filters.pageNr + 1 });
      }
    },
    setKeyword: debounce(function(val) {
      this.keyword = val;
    }, 500),
  },
};
</script>
